import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Button,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import Recaptcha from "./ReCaptcha";
import { useForm } from "react-hook-form";
import { textValidation, emailValidation } from "./Validation";
import SrrHeader from "../shared/components/SrrHeader";
import Acknowledgement from "../shared/components/Acknowledgement";
import { getService } from "../shared/components/restApi";

function FinancePrivacyIntakeRequest() {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  const [bpArray, setBpArray] = useState();
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [requestID, setRequestID] = useState("");
  const [assessmentID, setAssessmentID] = useState("");

  const { register, handleSubmit, formState, errors, validate } = useForm({
    mode: "onChange",
  });

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const onSubmit = (data) => {
    // submitted Data
    setSendRequest(data);
  };

  useEffect(() => {
    const getUrl = `${process.env.REACT_APP_MSFT_FINANCE_COE_BUSINESS_PROCESSES_API}`;

    const getResponse = getService({
      method: `GET`,
      url: getUrl,
    });
    getResponse.then((response) => {
      if (response.status === 200 && response.data) {
        // get the root id after successful request submit
        const { data } = response.data;
        const financeOrgBPs = data.filter(
          (obj) => obj.organization === "5b0868c16d485314aea9a568"
        );

        setBpArray(financeOrgBPs);
      }
    });
  }, []);

  useEffect(() => {
    if (sendRequest) {
      const { name, email, businessProcess } = sendRequest;
      // activate the loader
      setLoader(true);
      const randomString = (length) => {
        let mask =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        let result = "";
        for (let i = length; i > 0; --i)
          result += mask[Math.round(Math.random() * (mask.length - 1))];
        return result;
      };
      const idempotencyKey = randomString(16);
      // payload

      const payload = {
        name,

        organizationId: `${process.env.REACT_APP_MSFT_FINANCE_COE_ORGANIZATION}`,

        businessProcessId: businessProcess || null,

        assignee: email,

        idempotencyKey,
      };

      // API request

      const url = `${process.env.REACT_APP_MSFT_FINANCE_COE_ASSESSMENT_API}`;
      const submitOptions = {
        method: `POST`,
        url,
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (
            response.status === 200 &&
            response.data &&
            response.data.children
          ) {
            // get the root id after successful request submit
            const { assessmentExternalId, children } = response.data;
            const assessmentArray = children.filter(
              (task) => task.recipient === email
            );
            const id = assessmentArray.length
              ? assessmentArray[0].id
              : response.data.id;


            const checkStatus = setInterval(() => {
              const url = `${process.env.REACT_APP_MSFT_FINANCE_COE_CHECK_STATUS}/${assessmentExternalId}`;
              const getOptions = {
                method: "GET",
                url,
                Headers: {
                  "Access-Control-Allow-Origin": "*",
                },
              };

              const getResponse = getService(getOptions);
              getResponse.then((res) => {
                if (res.status === 200 && res.data) {
                  if (res.data.status === "ready") {
                    clearInterval(checkStatus);
                    setRequestID(id);
                    setAssessmentID(assessmentExternalId);

                    setLoader(false);
                    setShowAcknowledgement(true);
                  }
                }
              });
            }, 1000);
          }
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest, bpArray]);

  return (
    <>
      <SrrHeader
        heading="Finance Privacy Review Request Intake"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="Finance Privacy Review Request Intake"
      />

      <Container className="my-3">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement requestID={requestID} assessmentID={assessmentID} />
        ) : (
          <>
            <div className="text-justify">
              <h3>Finance Privacy Review Request</h3>
              <p>
                <strong>
                  Use this form to request a new privacy review or a change to an existing privacy review for any process/app/system that
                  {" "}
                  <u>processes personal data</u>.
                </strong>
              </p>
              <p>
                <strong>Instructions:</strong>
              </p>
              <ul>
                <li>
                  Enter Email Address of Requestor (use{" "}
                  <a
                    href="mailto: alias@microsoft.com"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    target="_blank"
                    rel="noreferrer noopener"
                    title="alias@microsoft.com"
                    className="mailAddress"
                  >
                    alias@microsoft.com
                  </a>{" "}
                  email format ONLY)
                </li>
                <li>
                  Enter Name of process/app/system (do <u>NOT</u> use browser
                  auto-fill)
                </li>
                <li>Select Business Process from drop-down list</li>
                <li>Click Submit Request</li>
                <li>Wait to be re-directed to the intake questionnaire in WireWheel tool (should take a few seconds)</li>
                <li>Fill out PIA Intake Questions (Section A) and Submit</li>
              </ul>
              <p><strong>Your request will be triaged in the order in which it was received. After triage is completed, you will receive additional questions and/or instructions to complete the privacy review. <u>PLEASE ALLOW FIVE (5) BUSINESS DAYS FOR TRIAGE.</u></strong></p>
              <p>
                <strong>
                  If you have questions, contact the Finance Privacy Team at{" "}
                  <a
                    href="mailto: privcon_fin@microsoft.co"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    target="_blank"
                    rel="noreferrer noopener"
                    title="privcon_fin@microsoft.co"
                    className="mailAddress"
                  >
                    privcon_fin@microsoft.com
                  </a>
                  .
                </strong>
              </p>
              <p>
                <a href="https://www.microsoft.com/en-us/privacy/data-privacy-notice">Data Protection Notice</a>
              </p>
            </div>
            <Card className="my-5 mx-auto form shadow">
              <Card.Body>
                <div className="form-title mb-3">
                  <Card.Text>
                    <strong>
                      Please provide the following information to start your
                      request:
                    </strong>
                  </Card.Text>
                  <Card.Text>
                    <span className="text-danger">*</span> Required fields
                  </Card.Text>
                </div>
                <Form className="form-inputs" onSubmit={handleSubmit(onSubmit)}>
                  <Form.Group className="mb-3 w-75">
                    <Form.Row>
                      <Form.Label className="fw-bold">
                        Email <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        autoComplete="off"
                        className={!errors.email ? "mb-2" : "error-border mb-2"}
                        ref={register(emailValidation)}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </Form.Row>

                    <Card.Text>
                      Enter email in <em>alias@microsoft.com</em> format only.
                    </Card.Text>
                  </Form.Group>

                  <Form.Group className="mb-3 w-75">
                    <Row>
                      <Col>
                        <Form.Label className="fw-bold">
                          Name of Process/App/System{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          autoComplete="off"
                          className={!errors.name ? "mb-2" : "error-border"}
                          ref={register(textValidation)}
                        />
                        {errors.name && (
                          <small className="text-danger">
                            {" "}
                            {errors.name.message}{" "}
                          </small>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>

                  <Form.Group className="mb-3 w-75">
                    <Row>
                      <Col>
                        <Form.Label className="fw-bold">
                          Business Processes{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <select
                          aria-label="business processes"
                          name="businessProcess"
                          ref={register({ required: true })}
                          className={
                            !errors.businessProcess
                              ? "mb-2 form-select"
                              : "error-border form-select"
                          }
                        >
                          <option value="">Select Business Process</option>

                          {bpArray &&
                            bpArray.map((bpObj) => (
                              <option key={bpObj._id} value={bpObj._id}>
                                {bpObj.name}
                              </option>
                            ))}
                        </select>

                        {errors.businessProcess && (
                          <small className="text-danger">
                            {" "}
                            {errors.businessProcess.message}{" "}
                          </small>
                        )}
                      </Col>
                    </Row>
                  </Form.Group>

                  <div className="d-flex justify-content-center mt-4">
                    <Recaptcha reCaptchaClick={handleReCaptchaClick} />
                  </div>

                  <Form.Group>
                    <Row className="d-flex justify-content-center mt-5">
                      <Col sm={12} md={10} lg={10} className="text-center">
                        <Button
                          type="submit"
                          variant="primary"
                          size="lg"
                          style={{
                            width: "inherit",
                          }}
                          disabled={
                            !formState.isValid || disableSubmit || validate
                          }
                        >
                          Submit Request
                        </Button>
                      </Col>
                    </Row>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </>
        )}
      </Container>
    </>
  );
}

export default FinancePrivacyIntakeRequest;
